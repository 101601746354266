/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { GridRowParams } from '@material-ui/data-grid';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import Search from '~/components/Search';
import { Context } from '~/context/auth';
import { LojaContext } from '~/context/loja';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import AbaGeral from './Abas/Geral';
import AbaPisCofins from './Abas/PIsCofins';
import AbaPdvFinanceiro from './Abas/PdvFinanceiro';
import AbaPerifericos from './Abas/Perifericos';
import { useRegraLoja } from './RegraLojaContext';
import { Container } from './styles';
import {
  IBalanca,
  IEtiqueta,
  ILoja,
  ISelect,
  tipoRegime,
  tipoRegimeFCP,
  tipoIntegraFrente,
  IInterface,
  tipoRegimePisCofins,
} from './types';
import { formatPercent } from './utils/formatPercent';
import { validaFormRegraLoja } from './utils/validaFormRegraLoja';
import { transformAsCurrency } from '~/utils/functions';
import { useQueryClient } from 'react-query';

const RegrasDeLoja: React.FC = () => {
  const queryClient = useQueryClient();

  const {
    formRegraLoja,
    changeFormRegraLoja,
    handleClearForms,
    aba,
    changeAba,
    toggleInitInicializado,
    initInicializado,
    isUpdate,
    toggleIsUpdate,
    balancasInsert,
    balancasUpdate,
    balancasDelete,
    setFormRegraLoja,
    formTela: {
      setValue,
      getValues,
      handleSubmit,
      setError,
      formState: { errors },
    },
    tipoTotalizadoresBalanca,
    qntdDigts,
    masterDetail,
  } = useRegraLoja();
  /**
   *  Campos Gerais
   */
  const [lojas, setLojas] = useState<ISelect[]>([]);
  const [lojasSemParametro, setLojasSemParametro] = useState<ISelect[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(true);

  const { handleChangeUserData } = useContext(Context);
  const user = useAuth();
  const { loja, handleLoja } = useContext(LojaContext);
  const handlelojaStorage = async (codloja: any) => {
    const { tipo_totalizador_etq_bal, qtd_dig_cod_bal } = getValues();
    try {
      const res = await api.get(`/lojas/${codloja}`);
      const { success, message } = res.data;
      if (!success) throw new Error(message);

      //  Atualizar informações do contexto da loja
      const lojaContext = loja;
      lojaContext.tipo_regime = res.data.data.tipo_regime;
      lojaContext.tipo_integra_frente =
        formRegraLoja.tipo_integra_frente.value.value;
      lojaContext.tipo_totalizador_etq_bal = tipo_totalizador_etq_bal.value;
      lojaContext.qtd_dig_cod_bal = qtd_dig_cod_bal.value;
      lojaContext.flg_sem_dig_verif_bal =
        formRegraLoja.flg_sem_dig_verif_bal.value;
      handleLoja(lojaContext);

      const temp = user.user;
      temp.loja = codloja;
      handleChangeUserData(temp);
    } catch (e: any) {
      toast.warn(e.message);
    }
  };

  /**
   * Campos Parametros da loja
   */

  /**
   * Effects
   */

  useEffect(() => {
    getLojas();
  }, [showSearch]);

  async function getLojasSemParametros() {
    const res = await api.get('/regraloja/lojas/0');

    const { data } = res.data;

    const regs = data.map((reg: ILoja) => {
      return {
        value: reg.cod_loja,
        label: `${reg.cod_loja} - ${reg.des_loja}`,
      };
    });
    setLojasSemParametro(regs);
    if (!isUpdate) {
      setLojas(regs);
    }
    return regs;
  }

  async function getLojasParametrizada() {
    const res = await api.get(
      `/regraloja/lojas/${formRegraLoja.cod_loja.value.value}`,
    );

    const { data } = res.data;

    const regs = data.map((reg: ILoja) => {
      return {
        value: reg.cod_loja,
        label: `${reg.cod_loja} - ${reg.des_loja}`,
      };
    });
    setLojas(regs);
  }

  async function getLojas() {
    if (!isUpdate) {
      getLojasSemParametros();
    } else {
      getLojasSemParametros();
      getLojasParametrizada();
    }
  }

  /**
   * Funções gerais
   */

  const onRowClick = async (param: GridRowParams) => {
    try {
      const { cod_loja } = param.row;

      await api
        .get(`/regraloja/${cod_loja}`)
        .then(async (res) => {
          const { success, data, message } = res.data;

          const {
            flg_st_bc_pis_cof,
            flg_ipi_bc_pis_cof,
            val_pis,
            val_cofins,
            cod_darf_pis,
            cod_darf_cofins,
            cst_pis_cof_entrada,
            cst_pis_cof_saida,
            cst_pis_cof_ent_aliq_zero,
            cst_pis_cof_saida_aliq_zero,
            cst_pis_cof_ent_monof,
            cst_pis_cof_saida_monof,
            cst_pis_cof_ent_subst,
            cst_pis_cof_saida_subst,
            cst_pis_cof_ent_imune,
            cst_pis_cof_saida_imune,
            cst_pis_cof_ent_susp,
            cst_pis_cof_saida_susp,
            tipo_regime,
            tipo_regime_fcp,
            tipo_regime_pis_cofins,
            flg_exc_icms_bc_pis_ent,
            val_desp_operacional,
            flg_trunca_val_deb_icms,
            flg_ignora_st_cf_custo,
            val_margem_venda,
            num_meses_vm,
            tipo_integra_frente,
            cod_interface_carga_pdv,
            des_path_carga_pdv,
            des_path_banco_frente,
            flg_compacta_arq_pdv,
            flg_enviar_plu_frente,
            cod_pessoa_sefaz,
            cod_cc_gare,
            cod_finalizadora_gare,
            num_condicao_gare,
            cod_condicao_gare,
            cod_categoria_gare,
            cod_cc_abertura,
            flg_ignora_tes,
            flg_tes_sem_turno,
            flg_tes_sem_bloq,
            tipo_totalizador_etq_bal,
            qtd_dig_cod_bal,
            flg_sem_dig_verif_bal,
          } = data;

          const Balancas = data.balancas.map((item: IBalanca) => {
            return {
              uuid: {
                value: nanoid(),
                isInvalid: false,
                isRequired: false,
              },
              cod_parametro_balanca: {
                value: { label: '', value: item.cod_parametro_balanca },
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              cod_interface: {
                value: { label: '', value: item.cod_interface },
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              des_path: {
                value: item.des_path,
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              flg_sem_digito_plu: {
                value: item.flg_sem_digito_plu,
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              flg_secao_unica: {
                value: item.flg_secao_unica,
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              flg_compacta_arq: {
                value: item.flg_compacta_arq,
                isInvalid: false,
                isRequired: false,
                invalidLabel: '',
              },
              flg_inativo: {
                value: item.flg_inativo,
                isInvalid: false,
                isRequired: false,
              },
            };
          });

          const etiquetas = data.etiquetas.map((item: IEtiqueta) => {
            return {
              nome_pc: {
                value: item.nome_pc,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              cod_parametro_etiqueta: {
                value: item.cod_parametro_etiqueta,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              des_parametro: {
                value: item.des_parametro,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              porta: {
                value: { label: '', value: item.tipo_porta },
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              linguagem: {
                value: { label: '', value: item.tipo_linguagem },
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              temperatura: {
                value: item.val_temperatura,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              dpi: {
                value: { label: '', value: item.tipo_dpi },
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              margem_esquerda: {
                value: item.val_margem_esquerda,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              velocidade: {
                value: item.val_velocidade,
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              pagina_codigo: {
                value: { label: '', value: item.tipo_pagina_codigo },
                isInvalid: false,
                isRequired: false,
                invalidLabel: undefined,
              },
              flg_inativo: {
                value: item.flg_inativo,
                isInvalid: false,
                isRequired: false,
              },
            };
          });

          setValue(
            'tipo_totalizador_etq_bal',
            tipoTotalizadoresBalanca.filter(
              (val: any) => val.value === tipo_totalizador_etq_bal,
            )[0],
          );
          setValue(
            'qtd_dig_cod_bal',
            qntdDigts.filter((val: any) => val.value === qtd_dig_cod_bal)[0],
          );

          setValue(
            'tipo_integra_frente',
            tipoIntegraFrente.find((item: ISelect) => {
              return item.value === tipo_integra_frente;
            }),
          );

          setValue(
            'tipo_regime',
            tipoRegime.find((item: ISelect) => item.value === tipo_regime),
          );

          setValue(
            'tipo_regime_fcp',
            tipoRegimeFCP.find((item: ISelect) => {
              return item.value === tipo_regime_fcp;
            }),
          );
          setValue('cod_loja', {
            value: cod_loja,
            label: `'${cod_loja}'`,
          });
          setValue('cod_interface_carga_pdv', {
            value: cod_interface_carga_pdv || undefined,
            label: '',
          });

          setValue('flg_st_bc_pis_cof', flg_st_bc_pis_cof);
          setValue('flg_ipi_bc_pis_cof', flg_ipi_bc_pis_cof);
          setValue('val_pis', val_pis);
          setValue('val_cofins', val_cofins);

          setValue('cod_darf_pis', cod_darf_pis);
          setValue('cod_darf_cofins', cod_darf_cofins);
          setValue('cst_pis_cof_entrada', {
            label: '',
            value: cst_pis_cof_entrada || undefined,
          });
          setValue('cst_pis_cof_saida', {
            label: '',
            value: cst_pis_cof_saida || undefined,
          });

          setValue('cst_pis_cof_ent_aliq_zero', {
            label: '',
            value: cst_pis_cof_ent_aliq_zero || undefined,
          });
          setValue('cst_pis_cof_saida_aliq_zero', {
            label: '',
            value: cst_pis_cof_saida_aliq_zero || undefined,
          });
          setValue('cst_pis_cof_ent_monof', {
            label: '',
            value: cst_pis_cof_ent_monof || undefined,
          });
          setValue('cst_pis_cof_saida_monof', {
            label: '',
            value: cst_pis_cof_saida_monof || undefined,
          });

          setValue('cst_pis_cof_ent_subst', {
            label: '',
            value: cst_pis_cof_ent_subst || undefined,
          });
          setValue('cst_pis_cof_saida_subst', {
            label: '',
            value: cst_pis_cof_saida_subst || undefined,
          });
          setValue('cst_pis_cof_ent_imune', {
            label: '',
            value: cst_pis_cof_ent_imune || undefined,
          });
          setValue('cst_pis_cof_saida_imune', {
            label: '',
            value: cst_pis_cof_saida_imune || undefined,
          });

          setValue('cst_pis_cof_ent_susp', {
            label: '',
            value: cst_pis_cof_ent_susp || undefined,
          });
          setValue('cst_pis_cof_saida_susp', {
            label: '',
            value: cst_pis_cof_saida_susp || undefined,
          });

          setValue(
            'tipo_regime_pis_cofins',
            tipoRegimePisCofins.find((item: ISelect) => {
              return item.value === tipo_regime_pis_cofins;
            }) || { label: 'Não cumulativo ', value: 0 },
          );
          setValue('flg_exc_icms_bc_pis_ent', flg_exc_icms_bc_pis_ent);
          setValue('val_desp_operacional', val_desp_operacional);
          setValue('flg_trunca_val_deb_icms', flg_trunca_val_deb_icms);

          setValue('flg_ignora_st_cf_custo', flg_ignora_st_cf_custo);
          setValue('val_margem_venda', val_margem_venda);
          setValue('num_meses_vm', num_meses_vm);
          setValue('des_path_carga_pdv', des_path_carga_pdv);
          setValue('des_path_banco_frente', des_path_banco_frente);
          setValue('flg_compacta_arq_pdv', flg_compacta_arq_pdv);

          setValue('flg_enviar_plu_frente', flg_enviar_plu_frente);
          setValue('cod_pessoa_sefaz', cod_pessoa_sefaz);
          setValue('cod_cc_gare', {
            value: cod_cc_gare || undefined,
            label: '',
          });
          setValue('cod_finalizadora_gare', {
            value: cod_finalizadora_gare || undefined,
            label: '',
          });
          setValue('num_condicao_gare', num_condicao_gare);

          setValue('cod_condicao_gare', {
            value: cod_condicao_gare || undefined,
            label: '',
          });
          setValue('cod_categoria_gare', {
            value: cod_categoria_gare || undefined,
            label: '',
          });
          setValue('cod_cc_abertura', {
            value: cod_cc_abertura || undefined,
            label: '',
          });
          setValue('flg_ignora_tes', flg_ignora_tes);
          setValue('flg_tes_sem_turno', flg_tes_sem_turno);

          setValue('flg_tes_sem_bloq', flg_tes_sem_bloq);
          setValue('flg_sem_dig_verif_bal', flg_sem_dig_verif_bal);

          changeFormRegraLoja({
            ...formRegraLoja,
            cod_loja: {
              ...formRegraLoja.cod_loja,
              value: { label: '', value: cod_loja },
              isInvalid: false,
            },
            flg_st_bc_pis_cof: {
              ...formRegraLoja.flg_st_bc_pis_cof,
              value: flg_st_bc_pis_cof,
              isInvalid: false,
            },
            flg_ipi_bc_pis_cof: {
              ...formRegraLoja.flg_ipi_bc_pis_cof,
              value: flg_ipi_bc_pis_cof,
              isInvalid: false,
            },
            val_pis: {
              ...formRegraLoja.val_pis,
              value: val_pis,
              isInvalid: false,
            },
            val_cofins: {
              ...formRegraLoja.val_cofins,
              value: val_cofins,
              isInvalid: false,
            },
            cod_darf_pis: {
              ...formRegraLoja.cod_darf_pis,
              value: cod_darf_pis,
              isInvalid: false,
            },
            cod_darf_cofins: {
              ...formRegraLoja.cod_darf_cofins,
              value: cod_darf_cofins,
              isInvalid: false,
            },
            cst_pis_cof_entrada: {
              value: { label: '', value: cst_pis_cof_entrada },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida: {
              value: { label: '', value: cst_pis_cof_saida },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_ent_aliq_zero: {
              value: { label: '', value: cst_pis_cof_ent_aliq_zero },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida_aliq_zero: {
              value: { label: '', value: cst_pis_cof_saida_aliq_zero },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_ent_monof: {
              value: { label: '', value: cst_pis_cof_ent_monof },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida_monof: {
              value: { label: '', value: cst_pis_cof_saida_monof },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_ent_subst: {
              value: { label: '', value: cst_pis_cof_ent_subst },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida_subst: {
              value: { label: '', value: cst_pis_cof_saida_subst },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_ent_imune: {
              value: { label: '', value: cst_pis_cof_ent_imune },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida_imune: {
              value: { label: '', value: cst_pis_cof_saida_imune },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_ent_susp: {
              value: { label: '', value: cst_pis_cof_ent_susp },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            cst_pis_cof_saida_susp: {
              value: { label: '', value: cst_pis_cof_saida_susp },
              isInvalid: false,
              isRequired: false,
              invalidLabel: undefined,
            },
            tipo_regime: {
              ...formRegraLoja.tipo_regime,
              value: {
                label: '',
                value: tipo_regime,
              },
              isInvalid: false,
            },
            tipo_regime_fcp: {
              ...formRegraLoja.tipo_regime_fcp,
              value: {
                label: '',
                value: tipo_regime_fcp,
              },
              isInvalid: false,
            },
            tipo_regime_pis_cofins: {
              ...formRegraLoja.tipo_regime_pis_cofins,
              value: {
                label: '',
                value: tipo_regime_pis_cofins,
              },
              isInvalid: false,
            },
            flg_exc_icms_bc_pis_ent: {
              ...formRegraLoja.flg_exc_icms_bc_pis_ent,
              value: flg_exc_icms_bc_pis_ent,
              isInvalid: false,
            },
            val_desp_operacional: {
              ...formRegraLoja.val_desp_operacional,
              value: val_desp_operacional,
              isInvalid: false,
            },
            flg_trunca_val_deb_icms: {
              ...formRegraLoja.flg_trunca_val_deb_icms,
              value: flg_trunca_val_deb_icms,
              isInvalid: false,
            },
            flg_ignora_st_cf_custo: {
              ...formRegraLoja.flg_ignora_st_cf_custo,
              value: flg_ignora_st_cf_custo,
              isInvalid: false,
            },
            val_margem_venda: {
              ...formRegraLoja.val_margem_venda,
              value: val_margem_venda,
              isInvalid: false,
            },
            num_meses_vm: {
              ...formRegraLoja.num_meses_vm,
              value: num_meses_vm,
              isInvalid: false,
            },
            tipo_integra_frente: {
              ...formRegraLoja.tipo_integra_frente,
              value: {
                value: tipo_integra_frente,
                label: '',
              },
              isInvalid: false,
            },
            cod_interface_carga_pdv: {
              ...formRegraLoja.cod_interface_carga_pdv,
              value: {
                value: cod_interface_carga_pdv,
                label: '',
              },
              isInvalid: false,
            },
            des_path_carga_pdv: {
              ...formRegraLoja.des_path_carga_pdv,
              value: des_path_carga_pdv,
              isInvalid: false,
            },

            des_path_banco_frente: {
              ...formRegraLoja.des_path_banco_frente,
              value: des_path_banco_frente,
              isInvalid: false,
            },
            flg_compacta_arq_pdv: {
              ...formRegraLoja.flg_compacta_arq_pdv,
              value: flg_compacta_arq_pdv,
              isInvalid: false,
            },
            flg_enviar_plu_frente: {
              ...formRegraLoja.flg_enviar_plu_frente,
              value: flg_enviar_plu_frente,
              isInvalid: false,
            },
            cod_pessoa_sefaz: {
              value: cod_pessoa_sefaz,
              isInvalid: false,
              isRequired: true,
            },
            cod_cc_gare: {
              value: { value: cod_cc_gare, label: '' },
              isInvalid: false,
              isRequired: true,
            },
            cod_finalizadora_gare: {
              value: { value: cod_finalizadora_gare, label: '' },
              isInvalid: false,
              isRequired: true,
            },
            num_condicao_gare: {
              value: num_condicao_gare,
              isInvalid: false,
              isRequired: true,
            },
            cod_condicao_gare: {
              value: { value: cod_condicao_gare, label: '' },
              isInvalid: false,
              isRequired: true,
            },
            cod_categoria_gare: {
              value: { value: cod_categoria_gare, label: '' },
              isInvalid: false,
              isRequired: true,
            },
            cod_cc_abertura: {
              value: { value: cod_cc_abertura, label: '' },
              isInvalid: false,
              isRequired: true,
            },
            flg_ignora_tes: {
              ...formRegraLoja.flg_ignora_tes,
              value: flg_ignora_tes,
              isInvalid: false,
            },
            flg_tes_sem_turno: {
              ...formRegraLoja.flg_tes_sem_turno,
              value: flg_tes_sem_turno,
              isInvalid: false,
            },
            flg_tes_sem_bloq: {
              ...formRegraLoja.flg_tes_sem_bloq,
              value: flg_tes_sem_bloq,
              isInvalid: false,
            },
            tipo_totalizador_etq_bal: {
              value: {
                label: '',
                value: tipo_totalizador_etq_bal,
              },
              isInvalid: false,
              isRequired: false,
              invalidLabel: '',
            },
            qtd_dig_cod_bal: {
              value: { label: '', value: qtd_dig_cod_bal },
              isInvalid: false,
              isRequired: false,
              invalidLabel: '',
            },
            flg_sem_dig_verif_bal: {
              ...formRegraLoja.flg_sem_dig_verif_bal,
              value: flg_sem_dig_verif_bal,
              isInvalid: false,
            },
            Balancas: {
              ...formRegraLoja.Balancas,
              balancas: Balancas,
            },
            Etiquetas: {
              ...formRegraLoja.Etiquetas,
              etiquetas,
            },
          });
          setValue('val_desp_operacional', formatPercent(val_desp_operacional));
          // setValue('val_margem_venda', formatPercent(val_margem_venda));
        })
        .catch((error) => {
          throw new Error(error.message);
        });

      toggleIsUpdate(true);
      setShowSearch(false);
    } catch (error: any) {
      toast.warn(
        `Não foi possível editar a regra de loja, por favor entre em contato com o suporte: ${error.message}`,
      );
    }
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (dataForm.num_condicao_gare <= 0 && dataForm.cod_pessoa_sefaz) {
      setError('num_condicao_gare', { type: 'focus' });
      return;
    }
    toggleInitInicializado(false);

    const masterDetailEtiqueta = masterDetail.find(
      (master) => master.obj_name === 'etiquetas',
    );

    const data: any = {
      cod_loja: dataForm.cod_loja.value,
      tipo_regime_fcp: dataForm.tipo_regime_fcp.value,
      tipo_regime: dataForm.tipo_regime.value,
      tipo_regime_pis_cofins: dataForm.tipo_regime_pis_cofins.value,
      flg_exc_icms_bc_pis_ent: dataForm.flg_exc_icms_bc_pis_ent,
      val_desp_operacional:
        transformAsCurrency(dataForm.val_desp_operacional) || 0,
      flg_trunca_val_deb_icms: dataForm.flg_trunca_val_deb_icms,
      flg_ignora_st_cf_custo: dataForm.flg_ignora_st_cf_custo,
      // val_margem_venda: transformAsCurrency(val_margem_venda) || 0,
      // num_meses_vm: formRegraLoja.num_meses_vm.value || 0,
      tipo_integra_frente: dataForm.tipo_integra_frente.value,
      cod_interface_carga_pdv: dataForm.cod_interface_carga_pdv.value,
      des_path_banco_frente: dataForm.des_path_banco_frente,
      des_path_carga_pdv: dataForm.des_path_carga_pdv,
      flg_compacta_arq_pdv: dataForm.flg_compacta_arq_pdv,
      flg_enviar_plu_frente: dataForm.flg_enviar_plu_frente,
      val_pis: dataForm.val_pis,
      val_cofins: dataForm.val_cofins,
      flg_st_bc_pis_cof: dataForm.flg_st_bc_pis_cof,
      flg_ipi_bc_pis_cof: dataForm.flg_ipi_bc_pis_cof,
      cod_darf_pis: dataForm.cod_darf_pis,
      cod_darf_cofins: dataForm.cod_darf_cofins,
      cst_pis_cof_entrada: dataForm.cst_pis_cof_entrada.value || undefined,
      cst_pis_cof_saida: dataForm.cst_pis_cof_saida.value || undefined,
      cst_pis_cof_ent_aliq_zero: dataForm.cst_pis_cof_ent_aliq_zero.value,
      cst_pis_cof_saida_aliq_zero: dataForm.cst_pis_cof_saida_aliq_zero.value,
      cst_pis_cof_ent_monof: dataForm.cst_pis_cof_ent_monof.value,
      cst_pis_cof_saida_monof: dataForm.cst_pis_cof_saida_monof.value,
      cst_pis_cof_ent_subst: dataForm.cst_pis_cof_ent_subst.value,
      cst_pis_cof_saida_subst: dataForm.cst_pis_cof_saida_subst.value,
      cst_pis_cof_ent_imune: dataForm.cst_pis_cof_ent_imune.value,
      cst_pis_cof_saida_imune: dataForm.cst_pis_cof_saida_imune.value,
      cst_pis_cof_ent_susp: dataForm.cst_pis_cof_ent_susp.value,
      cst_pis_cof_saida_susp: dataForm.cst_pis_cof_saida_susp.value,
      cod_pessoa_sefaz: dataForm.cod_pessoa_sefaz,
      cod_cc_gare: dataForm.cod_cc_gare.value,
      cod_finalizadora_gare: dataForm.cod_finalizadora_gare.value,
      num_condicao_gare: dataForm.num_condicao_gare || 0,
      cod_condicao_gare: dataForm.cod_condicao_gare.value,
      cod_categoria_gare: dataForm.cod_categoria_gare.value,
      flg_ignora_tes: dataForm.flg_ignora_tes,
      flg_tes_sem_turno: dataForm.flg_tes_sem_turno,
      flg_tes_sem_bloq: dataForm.flg_tes_sem_bloq,
      tipo_totalizador_etq_bal: dataForm.tipo_totalizador_etq_bal.value,
      qtd_dig_cod_bal: dataForm.qtd_dig_cod_bal.value,
      flg_sem_dig_verif_bal: dataForm.flg_sem_dig_verif_bal,
      balancas: {
        insert: balancasInsert,
        update: balancasUpdate,
        delete: balancasDelete,
      },
    };

    if (masterDetailEtiqueta) {
      data.etiquetas = masterDetailEtiqueta.itens;
    }

    if (dataForm.cod_cc_abertura) {
      data.cod_cc_abertura = dataForm.cod_cc_abertura.value;
    }

    if (dataForm.flg_enviar_plu_frente) {
      if (
        [0, 3, 4].indexOf(formRegraLoja.tipo_integra_frente.value.value) > -1
      ) {
        data.flg_enviar_plu_frente = true;
      } else {
        data.flg_enviar_plu_frente = false;
      }
    }

    try {
      if (isUpdate) {
        const res = await api.put(
          `/regraloja/${dataForm.cod_loja.value}`,
          data,
        );
        const { success, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        toast.success(message);
        toggleIsUpdate(false);

        if (loja.cod_loja === dataForm.cod_loja.value) {
          queryClient.setQueryData(
            `tipo_regime_loja_${loja.cod_loja}`,
            dataForm.tipo_regime.value,
          );
          handlelojaStorage(dataForm.cod_loja.value);
        }

        handleClearForms();
        return setShowSearch(true);
      }
      const res = await api.post(`/regraloja`, data);
      const { success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }
      toast.success(message);

      // AJUSTAR CHECAGEM DE LOJA SEM PARAMETRO
      const lojasParametro = await getLojasSemParametros();
      if (lojasParametro.length !== 0) {
        handleClearForms();
        return setShowSearch(false);
      }

      setShowSearch(true);
      onClearForm();
    } catch (e: any) {
      toast.warn(e.message);
    }
  });

  async function onDelete() {
    const tratamentoMsg = (message: string) => {
      if (message.indexOf('violates foreign key constraint') !== -1) {
        return toast.warn(
          'Erro ao excluir regra de loja. A regra já está vinculada.',
        );
      }
      return toast.warn(message);
    };
    try {
      const res = await api.delete(
        `/regraloja/${formRegraLoja.cod_loja.value.value}`,
      );
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      setShowSearch(true);
      onClearForm();
      toast.success(message);
    } catch (error: any) {
      tratamentoMsg(error.message);
    }
  }

  const onNewData = async () => {
    if (lojasSemParametro.length === 0) {
      toast.warn('Não há novas lojas para serem parametrizadas.');
    } else {
      toggleIsUpdate(false);
      handleClearForms();
      setShowSearch(false);
      toggleIsUpdate(false);
    }
  };

  async function onCancel() {
    toggleIsUpdate(false);
    handleClearForms();
    setShowSearch(true);
  }

  function onClearForm() {
    handleClearForms();
    toggleIsUpdate(false);
  }

  return (
    <>
      <Container>
        {showSearch && (
          <Search codTela={25} newData={onNewData} onRowClick={onRowClick} />
        )}

        {!showSearch && (
          <FormDefault
            codTela={25}
            title="Regra de Negócios (Lojas)"
            codigoRegistro={[
              {
                value: formRegraLoja.cod_loja.value.value,
                des_campo: 'Código',
              },
            ]}
            onSave={onSave}
            onCancel={onCancel}
            isUpdate={isUpdate}
            onNew={onNewData}
            onDelete={onDelete}
            onClearFields={onClearForm}
            onReturnSearch={onCancel}
          >
            <div className="row mb-3">
              <div className="col-sm-12 col-md-12">
                <InputSelect
                  options={lojas}
                  label="Loja"
                  value={
                    lojas.find((item: ISelect) => {
                      return item.value === formRegraLoja.cod_loja.value.value;
                    }) ?? {
                      value: -1,
                      label: 'Selecione',
                    }
                  }
                  isDisabled={isUpdate}
                  isRequired
                  setInvalid={!!errors.cod_loja}
                  iniInicializado={!!errors.cod_loja}
                  onChange={(value: any, isInvalid = true) => {
                    setValue('cod_loja', value);
                    changeFormRegraLoja({
                      ...formRegraLoja,
                      cod_loja: {
                        ...formRegraLoja.cod_loja,
                        value,
                        isInvalid: value.value === undefined,
                      },
                    });
                  }}
                />
              </div>
            </div>

            <Tabs
              id="controlled-regra_de_loja"
              activeKey={aba}
              onSelect={(k) => changeAba(k)}
            >
              <Tab eventKey="geral" title="Geral">
                <AbaGeral />
              </Tab>

              <Tab eventKey="piscofins" title="PIS/Cofins">
                <AbaPisCofins />
              </Tab>

              <Tab eventKey="perifericos" title="Periféricos">
                <AbaPerifericos />
              </Tab>

              <Tab eventKey="pdvfinanceiro" title="PDV / Financeiro">
                <AbaPdvFinanceiro />
              </Tab>
            </Tabs>
          </FormDefault>
        )}
      </Container>
    </>
  );
};

export default RegrasDeLoja;
