import React, { useEffect, useRef } from 'react';
import { Container } from './styles';
import Search from '~/components/Search';

const RelatorioLogsCargas: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }

  const containerRef = useRef(null);

  useEffect(() => {
    // Função para adicionar o hint
    const addHints = () => {
      const element = document.getElementsByTagName('tr');
      for (let i = 1; i < element.length; i++) {
        const log = element[i].cells[3].innerText;
        element[i].setAttribute('title', log);
      }
    };

    // Observer criado para verificar alterações da dom dentro do container (de referencia containerRef).
    // Caso ocorra alguma mudança na dom dentro do container e algum desses elementos seja a tag TR, o observer chamará a função addHint, que
    // adicionará o title com o texto do log à linha correspondente.
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length) {
          mutation.addedNodes.forEach((node) => {
            // Verifica se o nó é um Element e contém uma tabela
            if (
              node instanceof Element &&
              (node.nodeName === 'TR' || node.querySelector('tr'))
            ) {
              addHints();
            }
          });
        }
      });
    });

    const config = { childList: true, subtree: true };

    if (containerRef.current) observer.observe(containerRef.current, config);

    return () => observer.disconnect();
  }, []);

  return (
    <Container ref={containerRef}>
      <Search
        codTela={174}
        onlyReport
        newData={defaultOnlyReport}
        onRowClick={defaultOnlyReport}
      />
    </Container>
  );
};

export default RelatorioLogsCargas;
