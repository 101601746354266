import React from 'react';
import { Container } from './styles';
import Search from '~/components/Search';

const RelatorioSaidaNFE: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }
  return (
    <Container>
      <Search
        codTela={91}
        onlyReport
        newData={defaultOnlyReport}
        onRowClick={defaultOnlyReport}
      />
    </Container>
  );
};

export default RelatorioSaidaNFE;
