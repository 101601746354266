import {
  Control,
  FieldValues,
  FormState,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

export interface IParametro {
  cod_loja: number;
  tipo_regime_fcp: number;
  tipo_regime: number;
  val_desp_operacional: number;
  flg_trunca_val_deb_icms: boolean;
  flg_ignora_st_cf_custo: boolean;
  val_margem_venda: number;
  num_meses_vm: number;
  tipo_integra_frente: number;
  cod_interface_carga_pdv: number;
  des_path_carga_pdv: string;
  des_path_banco_frente: string;
  flg_compacta_arq_pdv: boolean;
  flg_enviar_plu_frente: boolean;
}

export interface IFormRegraLoja {
  cod_loja: {
    value: { value: number | undefined; label: string };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_st_bc_pis_cof: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_ipi_bc_pis_cof: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_exc_icms_bc_pis_ent: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  val_pis: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  val_cofins: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_darf_pis: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_darf_cofins: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_entrada: {
    value: { label: string; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_ent_aliq_zero: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida_aliq_zero: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_ent_monof: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida_monof: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_ent_subst: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida_subst: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_ent_imune: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida_imune: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_ent_susp: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cst_pis_cof_saida_susp: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  tipo_regime: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  tipo_regime_fcp: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  val_desp_operacional: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_trunca_val_deb_icms: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_ignora_st_cf_custo: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  val_margem_venda: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  num_meses_vm: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  tipo_integra_frente: {
    value: { label: string | undefined; value: number };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_interface_carga_pdv: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_path_carga_pdv: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_path_banco_frente: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_compacta_arq_pdv: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_enviar_plu_frente: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  cod_pessoa_sefaz: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_cc_gare: {
    value: { label: string | undefined; value: number | undefined } | null;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_finalizadora_gare: {
    value: { label: string | undefined; value: number | undefined } | null;
    isInvalid: boolean;
    isRequired: boolean;
  };
  num_condicao_gare: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_condicao_gare: {
    value: { label: string | undefined; value: number | undefined } | null;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_categoria_gare: {
    value: { label: string | undefined; value: number | undefined } | null;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_cc_abertura: {
    value: { label: string | undefined; value: number | undefined } | null;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_ignora_tes: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_tes_sem_turno: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_tes_sem_bloq: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  tipo_totalizador_etq_bal: {
    value: { label: string | undefined; value: number };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  qtd_dig_cod_bal: {
    value: { label: string | undefined; value: number };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_sem_dig_verif_bal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  Etiquetas: {
    etiquetas: IFormEtiqueta[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  Balancas: {
    balancas: IFormBalanca[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  tipo_regime_pis_cofins: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
}

export interface IFormEtiqueta {
  nome_pc: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_parametro_etiqueta: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_parametro: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  porta: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  linguagem: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  temperatura: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  dpi: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  margem_esquerda: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  velocidade: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  pagina_codigo: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_inativo: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export interface IEtiqueta {
  id: string;
  nome_pc: string;
  cod_parametro_etiqueta: number | undefined;
  des_parametro: string;
  tipo_porta: number | undefined;
  tipo_linguagem: string | undefined;
  val_temperatura: number;
  tipo_dpi: number | undefined;
  val_margem_esquerda: number;
  val_velocidade: number;
  tipo_pagina_codigo: number | undefined;
  flg_inativo: boolean;
}

export interface IFormBalanca {
  uuid: { value: string; isInvalid: boolean; isRequired: boolean };
  cod_parametro_balanca: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_interface: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_path: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_secao_unica: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_sem_digito_plu: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_compacta_arq: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_inativo: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export interface IBalanca {
  id: number | undefined;
  uuid: string;
  cod_parametro_balanca: number | undefined;
  cod_interface: number | undefined;
  des_interface: string | undefined;
  des_path: string;
  flg_secao_unica: boolean;
  flg_sem_digito_plu: boolean;
  flg_compacta_arq: boolean;
  flg_inativo: boolean;
}

export interface ISelectType {
  value: number | string;
  label: string;
}

export interface ISelect {
  label: string;
  value: number | string;
}

export interface ILoja {
  cod_loja: number;
  des_loja: string;
}

export interface IInterface {
  cod_interface: number;
  des_interface: string;
}

export const tipoRegime: ISelect[] = [
  {
    value: 0,
    label: 'Lucro Real',
  },
  {
    value: 1,
    label: 'Lucro Presumido',
  },
  {
    value: 2,
    label: 'Optante Simples',
  },
];

export const tipoRegimeFCP: ISelect[] = [
  {
    value: 0,
    label: 'Cumulativo',
  },
  {
    value: 1,
    label: 'Não Cumulativo',
  },
];

export const tipoIntegraFrente: ISelect[] = [
  {
    value: 0,
    label: 'KW',
  },
  {
    value: 1,
    label: 'ipdv',
  },
  {
    value: 2,
    label: 'Visual Mix',
  },
  {
    value: 3,
    label: 'Zanthus',
  },
  {
    value: 4,
    label: 'GZ',
  },
];

export const etiquetaPorta: ISelect[] = [
  {
    value: 0,
    label: 'COM1',
  },
  {
    value: 1,
    label: 'COM2',
  },
  {
    value: 2,
    label: 'COM3',
  },
  {
    value: 3,
    label: 'COM4',
  },
  {
    value: 4,
    label: 'COM5',
  },
  {
    value: 5,
    label: 'COM6',
  },
  {
    value: 6,
    label: 'COM7',
  },
  {
    value: 7,
    label: 'COM8',
  },
];

export const etiquetaLinguagem: ISelect[] = [
  {
    value: 'Ppla',
    label: 'Ppla',
  },
  {
    value: 'PPlb',
    label: 'PPlb',
  },
  {
    value: 'ZPLII',
    label: 'ZPLII',
  },
  {
    value: 'Epl2',
    label: 'Epl2',
  },
];

export const etiquetaDpi: ISelect[] = [
  {
    value: 203,
    label: '203',
  },
  {
    value: 300,
    label: '300',
  },
  {
    value: 600,
    label: '600',
  },
];
export const etiquetaPaginaCodigo: ISelect[] = [
  {
    value: 0,
    label: 'Nenhuma',
  },
  {
    value: 437,
    label: '437',
  },
  {
    value: 850,
    label: '850',
  },
  {
    value: 852,
    label: '852',
  },
  {
    value: 860,
    label: '860',
  },
  {
    value: 1250,
    label: '1250',
  },
  {
    value: 1252,
    label: '1252',
  },
];

export const tipoPisCofinsEntrada: ISelect[] = [
  {
    value: '50',
    label:
      '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '53',
    label:
      '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '54',
    label:
      '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '55',
    label:
      '55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '56',
    label:
      '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '60',
    label:
      '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '61',
    label:
      '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
  },
  {
    value: '62',
    label:
      '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação',
  },
  {
    value: '63',
    label:
      '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '64',
    label:
      '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '65',
    label:
      '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '66',
    label:
      '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '67',
    label: '67 - Crédito Presumido - Outras Operações',
  },
  {
    value: '70',
    label: '70 - Operação de Aquisição sem Direito a Crédito',
  },
  {
    value: '71',
    label: '71 - Operação de Aquisição com Isenção',
  },
  {
    value: '72',
    label: '72 - Operação de Aquisição com Suspensão',
  },
  {
    value: '73',
    label: '73 - Operação de Aquisição a Alíquota Zero',
  },
  {
    value: '74',
    label: '74 - Operação de Aquisição sem Incidência da Contribuição',
  },
  {
    value: '75',
    label: '75 - Operação de Aquisição por Substituição Tributária',
  },
  {
    value: '98',
    label: '98 - Outras Operações de Entrada',
  },
  {
    value: '99',
    label: '99 - Outras Operações',
  },
];

export const tipoPisCofinsSaida: ISelect[] = [
  {
    value: '01',
    label: '01 - Operação Tributável com Alíquota Básica',
  },
  {
    value: '02',
    label: '02 - Operação Tributável com Alíquota Diferenciada',
  },
  {
    value: '03',
    label:
      '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
  },
  {
    value: '04',
    label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
  },
  {
    value: '05',
    label: '05 - Operação Tributável por Substituição Tributária',
  },
  {
    value: '06',
    label: '06 - Operação Tributável a Alíquota Zero',
  },
  {
    value: '07',
    label: '07 - Operação Isenta da Contribuição',
  },
  {
    value: '08',
    label: '08 - Operação sem Incidência da Contribuição',
  },
  {
    value: '09',
    label: '09 - Operação com Suspensão da Contribuição',
  },
  {
    value: '49',
    label: '49 - Outras Operações de Saída',
  },
];

export const tipoRegimePisCofins: ISelect[] = [
  {
    value: 0,
    label: 'Não Cumulativo',
  },
  {
    value: 1,
    label: 'Cumulativo',
  },
];

export interface Condicao {
  cod_condicao: string;
  des_condicao: string;
  des_definicao: string;
}

export type UseFormProps = {
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  resetField: UseFormResetField<FieldValues>;
};
