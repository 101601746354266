import React from 'react';
import { Container } from './styles';
import Search from '~/components/Search';

const RelatorioEntradaNFE: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }
  return (
    <Container>
      <Search
        codTela={90}
        onlyReport
        newData={defaultOnlyReport}
        onRowClick={defaultOnlyReport}
      />
    </Container>
  );
};

export default RelatorioEntradaNFE;
